<template>
  <div>
    <div class="super">
      <div class="holder">
        <Header />
        <div class="wrapper">
          <div class="property_details container">
            <div class="row">
              <div class="col-md-4">
                <div class="prop_img" v-if="propertyData">
                  <img :src="propertyData.images[0]" alt="property" />
                </div>
              </div>
              <div class="col-md-8">
                <div class="details" v-if="propertyData">
                  <h3>
                    {{ Number(propertyData.price).toLocaleString() }}
                    <span>Per annum</span>
                  </h3>
                  <p>
                    {{ propertyData.address }} {{ propertyData.city }}
                    {{ propertyData.state }}
                  </p>
                  <h6 class="prop_features">
                    <div>
                      <img src="../assets/images/bed.svg" alt="bedrooms" />
                      <h6>{{ propertyData.bedrooms }}bedrooms</h6>
                    </div>
                    <div>
                      <img src="../assets/images/bathroom.svg" alt="bathroom" />
                      <h6>{{ propertyData.bathrooms }} bathrooms</h6>
                    </div>
                    <div>
                      <img src="../assets/images/toilet.svg" alt="toilet" />
                      <h6>{{ propertyData.toilets }} toilets</h6>
                    </div>
                    <div>
                      <img src="../assets/images/parking.svg" alt="parking" />
                      <h6>{{ propertyData.parking }} parking</h6>
                    </div>
                  </h6>
                </div>
              </div>
            </div>
            <hr class="horizontal_line" />

            <div class="info">
              <h3>Here is what you need to do:</h3>
              <div>
                <div class="info_option">
                  <div class="circle">1</div>
                  <p>Select your preferred tour date.</p>
                </div>
                <div class="info_option">
                  <div class="circle">2</div>
                  <p>
                    Fill in your personal details.
                  </p>
                </div>
                <div class="info_option">
                  <div class="circle">3</div>
                  <p>Enjoy your tour.</p>
                </div>

                <div class="pick_tour_btn" v-if="!schedule">
                  <button
                    class="btn btn-block"
                    @click="
                      schedule = 'date';
                      scrollToDate();
                    "
                  >
                    PICK A TOUR DATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="schedulearea">
          <div class="wrapper-2" v-if="schedule">
            <div class="container">
              <!-- Schedule date selection -->
              <div>
                <div class="first_section" v-if="schedule === 'date'">
                  <div class="schedule_date">
                    <h3>Pick your tour schedule</h3>
                    <p>
                      Self-tours allows you to visit on designated days of the
                      week from 10 a.m. to 4 p.m. without an agent.
                    </p>
                    <div
                      class=""
                      style="font-weight: bold; font-size: 40px; margin-bottom: 0px;"
                    >
                      <div class="row">
                        <div class="form-group w-100 col-md-6">
                          <!-- v:bind:selected="country.id===user.countryid" -->
                          <select
                            v-model="datebooked"
                            name="country"
                            class="form-control col-md-6 col-sm-12 date_select"
                            id="exampleFormControlSelect2"
                          >
                            <option value="" disabled>Select Tour Date</option>
                            <option
                              v-for="(item, index) in tourdates"
                              :key="index"
                              v-bind:value="item"
                              >{{ item }}</option
                            >
                          </select>
                        </div>

                        <div class="form-group w-100 col-md-6">
                          <!-- v:bind:selected="country.id===user.countryid" -->
                          <select
                            v-model="timebooked"
                            name="country"
                            class="form-control col-md-6 col-sm-12 date_select"
                            id="exampleFormControlSelect2"
                          >
                            <option value="" disabled>Select Tour Time</option>
                            <option
                              v-for="(item, index) in tourtimes"
                              :key="index"
                              v-bind:value="item.time"
                              >{{ item.time }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="footer_buttons second_section">
                  <div v-if="schedule === 'date'">
                    <p>These are the open days for booking</p>
                    <p>
                      Already have an account?
                      <span
                        ><router-link class="link_login" to="login"
                          >Login</router-link
                        ></span
                      >
                      to schedule with your details.
                    </p>
                  </div>

                  <div class="container">
                    <div
                      class="navigator_btn first_navigator"
                      v-if="schedule === 'date'"
                    >
                      <!-- <button class="btn back" @click="backToDate('date')">
                      BACK
                    </button> -->
                      <button class="btn next" @click="nextDetails('details')">
                        NEXT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Schedule date selection -->

              <div v-if="schedule === 'details'">
                <div class="">
                  <form class="container">
                    <div class="details_header">
                      <h3>Contact details to reach you</h3>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="fname">First name <span>*</span></label>
                          <input
                            :class="{ errorData: info && fname === '' }"
                            type="text"
                            v-model="fname"
                            name="fname"
                            class="form-control"
                            id="fname"
                          />
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="lname">Last name <span>*</span></label>
                          <input
                            :class="{ errorData: info && lname === '' }"
                            type="text"
                            v-model="lname"
                            name="lname"
                            class="form-control"
                            id="lname"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="email"
                            >Email address <span>*</span></label
                          >
                          <input
                            :class="{ errorData: info && email === '' }"
                            type="email"
                            v-model="email"
                            class="form-control"
                            id="email"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="phone">Phone Number <span>*</span></label>
                          <input
                            :class="{ errorData: info && phone === '' }"
                            type="text"
                            v-model="phone"
                            class="form-control"
                            id="phone"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1"
                        >Your Message (Optional)</label
                      >
                      <textarea
                        v-model="message"
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="6"
                      ></textarea>
                    </div>
                  </form>
                </div>

                <div class="container">
                  <div class="footer_buttons">
                    <div class="navigator_btn" v-if="schedule === 'details'">
                      <button class="btn back" @click="backToDate('date')">
                        BACK
                      </button>
                      <button
                        class="btn schedule"
                        @click="finish('finish')"
                        :disabled="tourBtnDisabled"
                      >
                        SCHEDULE A TOUR
                        <i
                          class="fa fa-spinner fa-spin  ml-1 f-12  btn-loader"
                          v-if="loader"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="schedule === 'finish'">
                <div class="container">
                  <div class="check_header">
                    <div class="check">
                      <img
                        src="../assets/images/calender-check.svg"
                        alt="done"
                      />
                      <h5>Your home tour has been scheduled</h5>
                    </div>

                    <div class="booking_details">
                      <p>Here are your booking details:</p>
                      <p>Address:</p>
                      <p>
                        {{ propertyData.address }} {{ propertyData.city }}
                        {{ propertyData.state }}
                      </p>
                      <p>Schedule:</p>
                      <p>{{ datebooked }} {{ timebooked }}</p>
                    </div>
                  </div>
                  <div class="next_step_text">
                    <p>
                      Next step. Please verify your identity to access the
                      property on the day of the tour
                    </p>
                  </div>
                  <div class="finish_btn">
                    <button class="btn finish" @click="finishBooking()">
                      FINISH
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../views/Header";
import { Service } from "../store/service";
const Api = new Service();
import moment from "moment";
import $ from "jquery";

export default {
  name: "Tour",
  components: {
    Header
  },
  data() {
    return {
      loader: false,
      loader2: false,
      info: false,
      tourBtnDisabled: false,
      schedule: "",
      user: "",
      tourdates: "",
      datebooked: "",
      timebooked: "",
      fname: "",
      lname: "",
      message: "",
      phone: "",
      email: "",
      tourtimes: [
        { time: "10AM" },
        { time: "11AM" },
        { time: "12PM" },
        { time: "1PM" },
        { time: "2PM" },
        { time: "3PM" },
        { time: "4PM" }
      ],
      propertyData: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.getToreDates();
    if (Object.keys(Api.getPropertyDetails()).length === 0) {
      this.$router.go(-1);
    } else {
      this.propertyData = Api.getPropertyDetails();
    }
    // this.propertyData = Api.getPropertyDetails();
    // if (this.propertyData.id === undefined || this.propertyData.id === null) {
    //   // this.$router.go(-1);
    // }
    // this.propertyData = Api.getPropertyDetails();
  },
  methods: {
    async scrollToDate() {
      const element = document.getElementById("schedulearea");
      // window.scroll({top: element.offsetTop, behavior: 'smooth'});
      await element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      });
      // const yOffset = -100;
      // // const element = document.getElementById(id);
      // const y =
      //   element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      // window.scrollTo({ top: y, behavior: "smooth" });
    },
    closeModal: function() {
      $("#getStartedLandlord").modal("hide");
      $("#letsMeetYou").modal("show");
    },
    nextDetails(data) {
      if (this.datebooked && this.timebooked) {
        this.schedule = data;
      } else {
        return this.$store.commit(
          "setApiFailed",
          "Select date and time before you proceed."
        );
      }
    },
    backToDate(data) {
      this.schedule = data;
    },
    finish(data) {
      this.bookTour(data);
    },
    getToreDates() {
      Api.getRequest("getdatesfortour")
        .then(res => {
          this.tourdates = res.data.dates;
        })
        .catch(err => {
          console.log("err", err);
        });
    },
    async bookTour(data) {
      this.info = true;
      this.loader = true;
      if (
        this.fnam === "" ||
        this.lname === "" ||
        this.email == "" ||
        this.phone === ""
      ) {
        this.info = true;
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill all required fields");
      } else {
        try {
          this.tourBtnDisabled = true;
          await Api.postRequest(`bookatour`, {
            firstname: this.fname,
            lastname: this.lname,
            email: this.email,
            phone: this.phone,
            message: this.message,
            propertyid: this.propertyData.id,
            datebooked: this.datebooked,
            timebooked: this.timebooked
          })
            .then(res => {
              if (res.data.success) {
                this.tourData = res.data;
                this.$store.commit("setApiSuccess", "Success");
                this.schedule = data;
                this.loader = false;
                this.tourBtnDisabled = false;
              }
            })
            .catch(err => {
              console.log("err", err);
              this.loader = false;
            });
        } finally {
          // console.log("closed");
        }
      }
    },
    finishBooking() {
      this.$router.go(-1);
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background: #efefee;
}

.wrapper {
  margin: 140px 20px 20px;
  padding: 40px 15px;
  background: $secondary;
  border-radius: 5px;
}

.errorData {
  border: 0.5px solid #dc3545 !important;
}
.wrapper-2 {
  margin: 10px 20px 20px;
  // padding: 40px 15px;
  padding: 10px 15px 40px;
  background: $secondary;
  border-radius: 5px;
}

// .first_section {
//   transform: scaleY(0);
//   transition: transform 400ms ease 0ms;
// }
// .second_section {
//   transform: scaleY(1);
//   transition: transform 400ms ease 0ms;
// }

.date_select {
  height: 50px;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
  max-width: 100%;
}

.prop_img {
  img {
    // max-width: 285px;
    width: 100%;
    border-radius: 5px;
    max-height: 169px;
    height: 100%;
    object-fit: cover;
  }
}

.details {
  text-align: start;
  margin-top: 20px;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: $black;
    span {
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
    }
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: $black;
    // margin-bottom: 0;
  }
}
.prop_features {
  display: flex;
  justify-content: left;
  div {
    text-align: center;
    padding: 0 10px;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: $black;
  }
  span {
    padding-right: 10px;
  }
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  color: $secondary;
  background: $primary;
  // border: 1px solid $primary;
  font-family: Gotham;
  margin-bottom: 20px;
}

.horizontal_line {
  background: #000000;
}

.info {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    color: $black;
    padding-top: 55px;
    padding-bottom: 30px;
  }
}

.info_option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p:nth-child(2) {
    margin-left: 24px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 26px;
    color: $black;
    text-align: center;
  }
}

.pick_tour_btn {
  margin-top: 84px;
  margin-bottom: 50px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    height: 50px;
    outline: 0;
    box-shadow: none;
  }
}

.schedule_date {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 54px;
    color: $black;
    text-align: left;
    padding-top: 30px;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 200;
    font-size: 23px;
    line-height: 34px;
    color: $black;
    text-align: left;
  }
}

.date_holer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_buttons {
  text-align: left;
  margin-top: 71px;
  div {
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 200;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      span {
        color: $primary;
      }
      .link_login {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}
.first_navigator {
  justify-content: right !important;
}
.navigator_btn {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back {
    width: 139px;
    height: 50px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    color: #c4c4c4;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    outline: 0;
    box-shadow: none;
  }
  .next {
    width: 139px;
    height: 50px;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border-radius: 5px;
    outline: 0;
    box-shadow: none;
  }
  .schedule {
    width: 235px;
    height: 50px;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border-radius: 5px;
    outline: 0;
    box-shadow: none;
  }
}

.details_header {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 54px;
    color: $black;
    text-align: left;
    margin-bottom: 48px;
  }
}

.form-group {
  margin-bottom: 50px;
  label {
    margin-bottom: 15px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    color: $black;
    display: flex;
    span {
      color: #ea0000;
      padding-left: 5px;
    }
  }
  input {
    border: 2px solid #c4c4c4;
    border-radius: 5px;
    height: 50px;
  }
  textarea {
    border: 2px solid #c4c4c4;
    border-radius: 5px;
  }
}

.check {
  padding-bottom: 50px;
  h5 {
    padding-top: 50px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: $black;
  }
}

.booking_details {
  background: #efefee;
  padding: 33px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: $black;
  }
}

.finish_btn {
  margin-top: 50px;
  text-align: right;
  .finish {
    background: $primary;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: $secondary;
    width: 139px;
    height: 50px;
    outline: 0;
    box-shadow: none;
  }
}

.next_step_text {
  margin-top: 30px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
}

input,
select {
  font-family: Gotham;
  font-size: 14px;
  box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
}
label {
  font-family: Gotham;
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  .wrapper {
    padding: 15px 15px 10px 15px;
  }

  .date_holer {
    display: block;
  }
  .schedule_date {
    h3 {
      font-size: 24px;
      line-height: 50px;
      padding-top: 0;
    }
    p:nth-child(2) {
      font-size: 16px;
      line-height: 31px;
    }
  }
  .details {
    h3 {
      span {
        // display: block;
        font-size: 13px;
      }
    }
    p {
      line-height: 26px;
    }
  }

  .prop_features {
    display: flex;
    justify-content: center;
    div {
      text-align: center;
      padding: 0 10px;
    }
    h6 {
      font-size: 9px;
      line-height: 35px;
    }
    span {
    }
  }

  .info {
    h3 {
      text-align: left;
      font-size: 20px;
      padding-top: 4px;
      padding-bottom: 10px;
    }
  }

  .booking_details {
    padding: 15px;
  }

  .info_option {
    display: block;
    margin-top: 20px;
    .circle {
      margin: auto;
      width: 30px;
      height: 30px;
      line-height: 34px;
      font-size: 13px;
    }
    p {
      text-align: left;
      // margin-bottom: 50px;
      margin-top: 10px;
      font-size: 16px !important;
    }
    p:nth-child(2) {
      margin-left: 0;
    }
  }

  .details_header {
    h3 {
      font-size: 30px;
      line-height: 46px;
    }
  }
  .container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .check {
    padding-bottom: 30px;
    img {
      width: 25%;
    }
    h5 {
      text-align: left;
      font-size: 23px;
      line-height: 40px;
    }
  }

  .booking_details {
    p {
      text-align: left;
    }
  }
  .next_step_text {
    margin-top: 20px;
    p {
      font-size: 16px;
      text-align: left;
    }
  }
  .footer_buttons {
    div {
      p {
        line-height: 30px;
      }
    }
  }
  .navigator_btn {
    .back {
      height: fit-content;
      font-size: 14px;
    }
    .next {
      height: fit-content;
      font-size: 14px;
    }
    .schedule {
      height: fit-content;
      font-size: 10px;
      width: fit-content;
    }
  }
  .finish_btn {
    .finish {
      height: fit-content;
      font-size: 15px;
    }
  }

  .pick_tour_btn {
    margin-top: 40px;
    margin-bottom: 10px;
    button {
      height: fit-content;
      font-size: 15px;
    }
  }

  .footer_buttons {
    margin-top: 0;
  }
  .form-group {
    margin-bottom: 1rem;
  }
}
</style>
